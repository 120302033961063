export const rfc3339 = ( d ) => {
	return d.getUTCFullYear() + '-' +
	pad( d.getUTCMonth() + 1 ) + '-' +
	pad( d.getUTCDate() ) + 'T' +
	pad( d.getUTCHours() ) + ':' +
	pad( d.getUTCMinutes() ) + ':' +
	pad( d.getUTCSeconds() ) + 'Z';
};

export const rfc3339Date = ( d ) => {
	return d.getUTCFullYear() + '-' +
	pad( d.getUTCMonth() + 1 ) + '-' +
	pad( d.getUTCDate() );
};

const pad = ( n ) => {
	return ( '0' + n ).slice( -2 );
};
