import { CloneList } from '../base/clone-list.js';
import { rfc3339Date } from '../../api/rfc-3339.js';

class MrTimeSlotList extends CloneList {

	constructor() {
		super();

		this._clickHandler = ( e ) => {
			if ( !e || !e.target ) {
				return;
			}

			if ( null !== e.target.getAttribute( 'grow-time-slot' ) ) {
				e.preventDefault();
				e.stopPropagation();

				this.grow();

				this.querySelector( 'input.js-from-date' ).dispatchEvent( new Event( 'change', {
					bubbles: true,
					cancelable: true,
				} ) );

				return;
			}

			if ( null !== e.target.getAttribute( 'remove-from-time-slot' ) ) {
				e.preventDefault();
				e.stopPropagation();

				const toRemove = parseInt( e.target.getAttribute( 'remove-from-time-slot' ), 10 );
				if ( Number.isNaN( toRemove ) ) {
					return;
				}

				this.removeListItem( toRemove );

				// update counters and ID's to ensure correctness
				for ( let i = 0; i < this.length; i++ ) {
					this.applyDefaultsOnListItem( i );
				}

				this.querySelector( 'input.js-from-date' ).dispatchEvent( new Event( 'change', {
					bubbles: true,
					cancelable: true,
				} ) );

				return;
			}
		};

		// Dirty hack to set initial state
		setTimeout( () => {
			requestAnimationFrame( () => {
				this.applyDefaultsOnListItem( 0 );

				const tomorrow = this.tomorrow();
				this.applyOnListItem( 0, {
					'.js-date-field': [
						{
							value: rfc3339Date( tomorrow ),
							min: rfc3339Date( tomorrow ),
						},
					],
				} );
			} );
		}, 50 );
	}

	// Life cycle
	connectedCallback() {
		this._addEventListeners();
	}

	disconnectedCallback() {
		this._removeEventListeners();
	}

	_addEventListeners() {
		this.addEventListener( 'click', this._clickHandler );
	}

	_removeEventListeners() {
		this.removeEventListener( 'click', this._clickHandler );
	}

	fieldID( n ) {
		return `${n}`;
	}

	fieldName( n ) {
		return `[${n}]`;
	}

	grow() {
		let max = parseInt( this.getAttribute( 'max' ), 10 );
		if ( Number.isNaN( max ) ) {
			max = 10;
		}

		const length = this.length;

		if ( length >= max ) {
			// already maxed out, nothing todo here
			return;
		}

		this.addListItems( 1, ( i ) => {
			return {
				'.js-date-field': [
					{
						id: `date-input-from--${this.fieldID( i )}`,
						name: `from-date${this.fieldName( i )}`,
						value: rfc3339Date( this.tomorrow() ),
					},
				],
				'.js-date-field-label': [
					{
						for: `date-input-from--${this.fieldID( i )}`,
					},
				],

				'.js-hour-field-from': [
					{
						id: `hour-input-from--${this.fieldID( i )}`,
						name: `from-date${this.fieldName( i )}`,
					},
				],
				'.js-hour-field-from-hour-label': [
					{
						for: `hour-input-from--${this.fieldID( i )}`,
					},
				],

				'.js-hour-field-until': [
					{
						id: `hour-input-until--${this.fieldID( i )}`,
						name: `until-date${this.fieldName( i )}`,
					},
				],
				'.js-hour-field-until-label': [
					{
						for: `hour-input-until--${this.fieldID( i )}`,
					},
				],

				'[remove-from-time-slot]': [
					{
						'remove-from-time-slot': `${i}`,
					},
				],

				'.js-time-slot-message': [
					{
						innerHTML: '',
						'data-has-error': null,
					},
				],
			};
		} );

		this.listItem( length ).querySelector( 'input' ).focus( {
			preventScroll: true,
		} );
	}

	applyDefaultsOnListItem( i ) {
		this.applyOnListItem( i, {
			'.js-date-field': [
				{
					id: `date-input-from--${this.fieldID( i )}`,
					name: `from-date${this.fieldName( i )}`,
				},
			],
			'.js-date-field-label': [
				{
					for: `date-input-from--${this.fieldID( i )}`,
				},
			],

			'.js-hour-field-from': [
				{
					id: `hour-input-from--${this.fieldID( i )}`,
					name: `from-date${this.fieldName( i )}`,
				},
			],
			'.js-hour-field-from-hour-label': [
				{
					for: `hour-input-from--${this.fieldID( i )}`,
				},
			],

			'.js-hour-field-until': [
				{
					id: `hour-input-until--${this.fieldID( i )}`,
					name: `until-date${this.fieldName( i )}`,
				},
			],
			'.js-hour-field-until-label': [
				{
					for: `hour-input-until--${this.fieldID( i )}`,
				},
			],

			'[remove-from-time-slot]': [
				{
					'remove-from-time-slot': `${i}`,
				},
			],
		} );
	}

	tomorrow() {
		const nowPlus1 = new Date();
		nowPlus1.setDate( nowPlus1.getDate() + 1 );

		return nowPlus1;
	}
}

customElements.define( 'mr-time-slot-list', MrTimeSlotList );
