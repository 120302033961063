import { apiAddress } from './config.js';
import { rfc3339 } from './rfc-3339.js';
import { handleMissingResponse } from './response.js';
import { bugsnagClient } from '@mrhenry/wp--bugsnag-config';
import { siteLang } from '../helpers/translations.js';

// Get if certain timeslot is available for a certain room
export const getAvailability = ( room_id, subroom_id, start, end ) => {
	// Set headers if needed
	const headers = new Headers();
	const url = new URL( '/api/events/check', apiAddress );
	const search = new URLSearchParams();

	search.append( 'end', rfc3339( end ) );
	search.append( 'start', rfc3339( start ) );
	search.append( 'room_id', room_id );
	search.append( 'language', siteLang() );

	if ( subroom_id ) {
		search.append( 'sub_room_ids[0]', subroom_id );
	}

	url.search = search.toString();

	// Create a request with a url and config as needed
	const request = new Request( url, {
		method: 'GET',
		headers: headers,
	} );

	// Do the request
	return fetch( request ).then( ( response ) => {

		// Handle HTTP errors
		if ( !response.ok ) {
			// just throw so we can handle in the catch block later
			throw new Error( `GET /api/events/check : ${response.status} ${response.statusText}` );
		}

		// this might error but we handle in the catch block later
		return response.json();
	} ).then( ( data ) => {
		handleMissingResponse( data );

		return data.response;
	} ).catch( ( e ) => {
		// Handle errors :
		// - connection errors
		// - HTTP/Server errors
		// - JSON decoding errors

		// report the error
		bugsnagClient.notify( e );
		// rethrow
		throw e;
	} );
};
