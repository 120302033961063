import { getAvailability } from '../../api/room-availability.js';
import { rfc3339, rfc3339Date } from '../../api/rfc-3339.js';
import { siteLang } from '../../helpers/translations.js';

class MrTimeRange extends HTMLElement {
	static get observedAttributes() {
		return [
			'room-id',
			'subroom-id',
		];
	}

	constructor() {
		super();

		this._stateHandler = ( e ) => {
			if ( !e || !e.detail ) {
				return;
			}

			if ( e.detail.step_room && e.detail.step_room.rooms ) {
				this.selected_room_id = '';
				this.selected_subroom_id = '';

				e.detail.step_room.rooms.forEach( ( room ) => {
					if ( !room.selected ) {
						return;
					}

					this.roomID = room.room_id;

					if ( room.sub_rooms && room.sub_rooms.length ) {
						room.sub_rooms.forEach( ( sub_room ) => {
							if ( !sub_room.selected ) {
								return;
							}

							this.subRoomID = sub_room.subroom_id;
						} );
					}
				} );
			}
		};

		this._changeHandler = ( e ) => {
			if ( !e ) {
				return;
			}

			if ( e.target.classList.contains( 'js-from-date' ) || e.target.classList.contains( 'js-until-date' ) ) {
				return;
			}

			e.preventDefault();
			e.stopPropagation();

			if ( e.target.classList.contains( 'js-date-field' ) ) {
				this._setHourSlots();
			}

			this._validateAndSetFormValue();
		};
	}

	_setHourSlots() {
		const dateValue = this.querySelector( '.js-date-field' ).value;
		const hourFieldOptions = this.querySelectorAll( '.js-hour-field option' );

		for ( let i = 0; i < hourFieldOptions.length; i++ ) {
			const option = hourFieldOptions[i];
			option.value = `${dateValue}T${option.dataset.hourSlot}`;

			if (
				option.dataset.hourSlot.startsWith( '00:' ) ||
				option.dataset.hourSlot.startsWith( '01:' ) ||
				option.dataset.hourSlot.startsWith( '02:' )
			) {
				const slotValue = new Date( `${dateValue}T${option.dataset.hourSlot}` );
				slotValue.setDate( slotValue.getDate() + 1 );
				option.value = rfc3339( slotValue );
			}
		}
	}

	async _validateAndSetFormValue() {
		// Gather Elements
		const fromDate = this.querySelector( '.js-hour-field-from' );
		const untilDate = this.querySelector( '.js-hour-field-until' );
		if ( !fromDate || !untilDate ) {
			console.warn( 'invalid view' );

			return;
		}

		const [
			fromDateValue,
			untilDateValue,
		] = fromDate.value <= untilDate.value ? [ // eslint-disable-line no-ternary
			fromDate.value,
			untilDate.value,
		] : [
			untilDate.value,
			fromDate.value,
		];

		// Helper function to set a clean state
		const setEmptyState = () => {
			const listItemMessageArea = this.querySelector( '.js-time-slot-message' );
			if ( !listItemMessageArea ) {
				console.warn( 'no message view for errors' );

				return;
			}

			listItemMessageArea.innerHTML = '';
			listItemMessageArea.removeAttribute( 'data-has-error' );

			fromDate.setCustomValidity( '' );
			fromDate.checkValidity();
			untilDate.setCustomValidity( '' );
			untilDate.checkValidity();
		};

		if ( !this.roomID ) {
			setEmptyState();

			return;
		}

		if ( !fromDateValue || !untilDateValue ) {
			setEmptyState();

			return;
		}

		const fromTime = new Date( fromDateValue );
		const untilTime = new Date( untilDateValue );
		if ( !this.isValidDate( fromTime ) || !this.isValidDate( untilTime ) ) {
			const listItemMessageArea = this.querySelector( '.js-time-slot-message' );
			if ( !listItemMessageArea ) {
				console.warn( 'no message view for errors' );

				return;
			}

			if ( 'en' === siteLang() ) {
				listItemMessageArea.innerHTML = 'Please enter a future date in YYYY-MM-DD format.';
			} else {
				listItemMessageArea.innerHTML = 'Vul een toekomstige datum in met formaat YYYY-MM-DD.';
			}

			listItemMessageArea.setAttribute( 'data-has-error', '' );

			return;
		}

		const availabilityCheckKey = `${this.roomID}--${this.subRoomID}--${fromTime}--${untilTime}`;
		if ( availabilityCheckKey === this.availabilityCheckKey ) {
			return;
		}

		this.availabilityCheckKey = availabilityCheckKey;

		return getAvailability(
			this.roomID,
			this.subRoomID,
			fromTime,
			untilTime
		).then( ( availability ) => {
			if ( this.availabilityCheckKey !== availabilityCheckKey ) {
				// not the latest check call
				return;
			}

			const listItemMessageArea = this.querySelector( '.js-time-slot-message' );
			if ( !listItemMessageArea ) {
				console.warn( 'no message view for errors' );

				return;
			}

			if ( ( availability.has_errors || availability.has_warnings ) && availability.messages && availability.messages.length ) {
				const messages = [];

				for ( let j = 0; j < availability.messages.length; j++ ) {
					if ( availability.messages[j].message ) {
						messages.push( availability.messages[j].message );
					}
				}

				listItemMessageArea.innerHTML = messages.join( '<br>' );
				listItemMessageArea.setAttribute( 'data-has-error', '' );

				fromDate.setCustomValidity( messages[0] );
				fromDate.checkValidity();
				untilDate.setCustomValidity( messages[0] );
				untilDate.checkValidity();

				return;
			}

			if ( 'en' === siteLang() ) {
				listItemMessageArea.innerHTML = 'Available!';
			} else {
				listItemMessageArea.innerHTML = 'Beschikbaar!';
			}

			listItemMessageArea.removeAttribute( 'data-has-error' );

			fromDate.setCustomValidity( '' );
			fromDate.checkValidity();
			untilDate.setCustomValidity( '' );
			untilDate.checkValidity();
		} ).catch( ( e ) => {
			// TODO : err handling
			console.warn( e );
		} );
	}

	isValidDate( date ) {
		const isValidDateInstance = date instanceof Date && !isNaN( date );
		const dateIsFuture = date > this.endOfDay();

		return isValidDateInstance && dateIsFuture;
	}

	endOfDay() {
		const endOfDay = new Date();
		endOfDay.setDate( endOfDay.getDate() + 1 );
		endOfDay.setHours( 0 );
		endOfDay.setMinutes( -1 );
		endOfDay.setSeconds( 0 );

		return endOfDay;
	}

	get roomID() {
		return this.getAttribute( 'room-id' ) || '';
	}

	// Implement disabled state
	set roomID( value ) {
		if ( null === value ) {
			this.removeAttribute( 'room-id' );
		} else {
			this.setAttribute( 'room-id', value );
		}
	}

	get subRoomID() {
		return this.getAttribute( 'subroom-id' );
	}

	// Implement disabled state
	set subRoomID( value ) {
		if ( null === value ) {
			this.removeAttribute( 'subroom-id' );
		} else {
			this.setAttribute( 'subroom-id', value );
		}
	}

	attributeChangedCallback( attrName, oldVal, newVal ) {
		if ( !this.inDOM() ) {
			return;
		}

		if ( 'room-id' !== attrName && 'subroom-id' !== attrName ) {
			return;
		}

		if ( oldVal === newVal ) {
			return;
		}

		// Validate on the next event loop cycle
		requestAnimationFrame( () => {
			this._validateAndSetFormValue();
		} );
	}

	// Override setAttribute to handle JSON values
	setAttribute( attr, value ) {
		if ( 'room-id' === attr ) {
			const val = `${value}`;
			if ( val === super.getAttribute( 'room-id' ) ) {
				return;
			}

			super.setAttribute( attr, val );

			return;
		}

		if ( 'subroom-id' === attr ) {
			const val = `${value}`;
			if ( val === super.getAttribute( 'subroom-id' ) ) {
				return;
			}

			super.setAttribute( attr, val );

			return;
		}

		super.setAttribute( attr, value );
	}

	// Life cycle
	connectedCallback() {
		requestAnimationFrame( () => {
			this._addEventListeners();

			{
				const dateField = this.querySelector( '.js-date-field' );
				if ( !dateField.value || ( new Date( dateField.value ) ).valueOf() < this.tomorrow().valueOf() ) {
					dateField.value = rfc3339Date( this.tomorrow() );
				}
			}

			this._setHourSlots();
			this._validateAndSetFormValue();
		} );
	}

	disconnectedCallback() {
		this._removeEventListeners();
	}

	_addEventListeners() {
		this.addEventListener( 'change', this._changeHandler );
		window.addEventListener( 'rent-space:state-change', this._stateHandler );
	}

	_removeEventListeners() {
		this.removeEventListener( 'change', this._changeHandler );
		window.removeEventListener( 'rent-space:state-change', this._stateHandler );
	}

	inDOM() {
		if ( !this.parentNode ) {
			return false;
		}

		if ( !document.body.contains( this ) ) {
			return false;
		}

		return true;
	}

	tomorrow() {
		const nowPlus1 = new Date();
		nowPlus1.setDate( nowPlus1.getDate() + 1 );

		return nowPlus1;
	}
}

customElements.define( 'mr-time-range', MrTimeRange );
